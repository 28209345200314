import { useState, useEffect, useCallback } from 'react'
import { getFirebaseFirestore } from '@babelcoder/gatsby-plugin-firebase'

import { useGlobalState } from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'

function useRegistration(slug) {
  const [isLoading, setIsLoading] = useState(true)
  const [exists, setExists] = useState(false)
  const { user, isAuthLoaded } = useGlobalState()
  const loadRegistrations = useCallback(async () => {
    if (!isAuthLoaded) return
    if (!user) {
      setExists(null)
      setIsLoading(false)
      return
    }

    try {
      const firestore = await getFirebaseFirestore()
      const doc = await firestore.doc(`users/${user.uid}`).get()

      setExists(doc.data().registrations[slug])
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [slug, isAuthLoaded, user])

  useEffect(() => {
    loadRegistrations()
  }, [loadRegistrations])

  return { isLoading, exists }
}

export default useRegistration
