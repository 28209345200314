import React, { useCallback } from 'react'
import { navigate } from 'gatsby'
import { graphql } from 'gatsby'

import AuthGuard from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import TermsAndConditions from '../../components/courses/TermsAndConditions'
import useCoursesPath from '../../hooks/useCoursesPath'

export default function TermsAndConditionsTemplate({ data: { course } }) {
  const { getCoursePath, getCourseRegisterPath } = useCoursesPath()

  const goToRegistrationPage = useCallback(() => {
    navigate(getCourseRegisterPath(course.slug), { replace: true })
  }, [getCourseRegisterPath, course.slug])

  return (
    <AuthGuard emailMustVerified={true} redirectTo={getCoursePath(course.slug)}>
      <TermsAndConditions
        {...course}
        onAccept={goToRegistrationPage}
      ></TermsAndConditions>
    </AuthGuard>
  )
}

export const pageQuery = graphql`
  query TermsAndConditionsQuery($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      title
      termsAndConditions
    }
  }
`
