import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'

import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle'
import Checkbox from '@babelcoder/gatsby-theme-base/src/components/Checkbox'
import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer'
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import NotRegisterOnlyGuard from './NotRegisterOnlyGuard'

const Wrapper = styled.section`
  ${({ theme }) => css`
    max-width: 768px;
    margin: ${theme.spacers.normal} auto;
    padding: 0 ${theme.spacers.normal};
  `}
`

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray1200};
    font-size: ${theme.fontSizes.medium};
    text-align: center;
    text-decoration: underline;
  `}
`

const Divider = styled.div`
  ${({ theme }) => css`
    height: 1px;
    margin: ${theme.spacers.normal} 0;
    background-color: ${theme.colors.neutral.gray400};
  `}
`

const RegisterButton = styled.button.attrs({ type: 'button' })`
  ${({ theme, $accept }) => css`
    display: block;
    margin: ${theme.spacers.normal} auto 0 auto;
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    background-color: ${theme.colors.neutral.gray700};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.medium};
    border-radius: 2rem;
    border: none;
    outline: none;
    transition: background-color 0.25s;

    ${$accept &&
    css`
      background-color: ${theme.colors.main.primary};
      cursor: pointer;
    `}
  `}
`

function TermsAndConditions({ slug, title, termsAndConditions, onAccept }) {
  const { getCourseRegisterPath } = useCoursesPath()
  const [accept, setAccept] = useState(false)

  const changeAccept = useCallback(
    (accept) => {
      setAccept(accept)
    },
    [setAccept]
  )

  const register = useCallback(() => {
    if (!accept) return

    onAccept()
  }, [accept, slug, getCourseRegisterPath])

  return (
    <NotRegisterOnlyGuard slug={slug}>
      <Wrapper>
        <PageTitle>เงื่อนไขการสมัครและใช้งานคอร์ส</PageTitle>
        <Title>{title}</Title>
        <MDXRenderer>{termsAndConditions}</MDXRenderer>
        <Divider></Divider>
        <Checkbox onChange={changeAccept} htmlFor="accept">
          ฉันยอมรับเงื่อนไขการสมัครและใช้งานคอร์ส
        </Checkbox>
        <RegisterButton $accept={accept} onClick={register}>
          ลงทะเบียน
        </RegisterButton>
      </Wrapper>
    </NotRegisterOnlyGuard>
  )
}

export default TermsAndConditions
