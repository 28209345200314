import React from 'react'
import { navigate } from 'gatsby'

import AuthGuard from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import LoadingImage from '@babelcoder/gatsby-theme-base/assets/images/loading.svg'
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath'
import useRegistration from '@babelcoder/gatsby-theme-courses/src/hooks/useRegistration'

function NotRegisterOnlyGuard({ slug, children }) {
  const { isLoading, exists } = useRegistration(slug)
  const { getCoursePath } = useCoursesPath()

  if (exists) {
    navigate(getCoursePath(slug), { replace: true })
    return null
  }

  return (
    <AuthGuard>
      {isLoading ? <LoadingImage></LoadingImage> : children}
    </AuthGuard>
  )
}

export default NotRegisterOnlyGuard
